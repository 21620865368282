import { React, useRef, useState, useEffect } from "react";

import Footer2 from "./Footer2";
import HeaderLower from "./HeaderLower";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import RegisterService from "../services/register.service";

import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import UserformService from "../services/userform.service";

import Select from "react-select";
import { toast } from "react-toastify";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    phone: "",
    emailid: "",
    store_location: "",
    sales_name_f: "",
    sales_name_l: "",
    question: "",
  });

  const [errors, setErrors] = useState({});

  const [locations, setLocations] = useState([]);
  const [question, setQuestion] = useState([
    {
      value: "Furniture",
      label: "Furniture",
    },
    {
      value: "Mattress",
      label: "Mattress",
    },
    {
      value: "Both",
      label: "Both",
    },
  ]);

  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);

  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");

  useEffect(() => {
    async function getLocations() {
      let errors = {};

      try {
        const response = await RegisterService.getStoreLocation();

        var locations = response.data.data;
        const results = [];
        locations.map((value) => {
          return results.push({
            value: value.store_location,
            label: value.store_location,
          });
        });
        await setLocations([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    getLocations();
  }, []);

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("return_page");
    localStorage.removeItem("registerForm");
    localStorage.removeItem("isRegister");
  };

  const ref = useRef();

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    if (name === "store_location" && value === "true") {
      value = "";
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    let data = {
      fname: fields?.name,
      lname: fields?.lname,
      phone: fields?.phone,
      email: fields?.emailid,
      store_location: fields?.store_location,
      sales_name_f: fields?.sales_name_f,
      sales_name_l: fields?.sales_name_l,
      question: fields?.question,
    };

    try {
      const responseuserform = RegisterService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);

          Swal.fire({
            title: "Successful!",
            text: "Form Submitted!",
            type: "success",
            icon: "success",
          }).then((result) => {
            secureLocalStorage.setItem(
              "registerform",
              JSON.stringify({
                name: fields.name,
                lname: fields.lname,
                phone: fields.phone,
                emailid: fields.emailid,
                store_location: fields.store_location,
                sales_name_f: fields.sales_name_f,
                sales_name_l: fields.sales_name_l,
                question: fields.question,
              })
            );
            localStorage.removeItem("return_page");
            localStorage.setItem("isRegister", true);
            navigate("/register/registration-thankyou");
          });

          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 400) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          let errorData = {};
          errorData["name"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.fname &&
            err?.response?.data?.message.fname[0];
          errorData["lname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.lname &&
            err?.response?.data?.message.lname[0];
          errorData["phone"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.phone &&
            err?.response?.data?.message.phone[0];
          errorData["emailid"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.email &&
            err?.response?.data?.message.email[0];
          errorData["store_location"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.store_location &&
            err?.response?.data?.message.store_location[0];
          errorData["sales_name_f"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales_name_f &&
            err?.response?.data?.message.sales_name_f[0];
          errorData["sales_name_l"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales_name_l &&
            err?.response?.data?.message.sales_name_l[0];
          errorData["question"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.question &&
            err?.response?.data?.message.question[0];
          errorData["checkederrorcaptcha"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.captcha &&
            err?.response?.data?.message.captcha[0];
          errorData["checkederrortwo"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.size &&
            err?.response?.data?.message.size[0];
          saveErrors(errorData);
          setTimeout(() => {
            ref?.current?.focus();
          }, 200);
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["name"] = err?.response?.data?.message.fname[0];
      errorData["lname"] = err?.response?.data?.message.lname[0];
      errorData["phone"] = err?.response?.data?.message.phone[0];
      errorData["emailid"] = err?.response?.data?.message.emailid[0];
      errorData["store_location"] =
        err?.response?.data?.message.store_location[0];
      errorData["sales_name_f"] = err?.response?.data?.message.sales_name_f[0];
      errorData["sales_name_l"] = err?.response?.data?.message.sales_name_l[0];
      errorData["question"] = err?.response?.data?.message.question[0];
      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message.captcha[0];
      saveErrors(errorData);
    }
  };
  // const validate = (e) => {
  //   let errors = {};
  //   let isformIsValid = true;

  //   if (!captcha) {
  //     isformIsValid = false;
  //     errors["checkederrorcaptcha"] = "Captcha not verified";
  //     saveErrors(errors);
  //     return isformIsValid;
  //   }

  //   return isformIsValid;
  // };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form" id="font-style">
            <h1>Register your guest for their $25 Mastercard Reward Card!</h1>
            <hr />
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
              // onClick={this.popup}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Customer First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Customer Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone Number <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    style={
                      errors.phone && !errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Customer Phone Number"
                    id="phone"
                    className="searchBox_deals vc-validate"
                    value={fields.phone || ""}
                    ref={
                      errors.phone && !errors.lname && !errors.name ? ref : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {errors.phone && !errors.lname && !errors.name
                      ? errors.phone
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="Customer Email Address"
                    value={fields.emailid || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid &&
                    !errors.phone &&
                    !errors.lname &&
                    !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Employee First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Employee First Name"
                    style={
                      errors.sales_name_f &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="sales_name_f"
                    id="sales_name_f"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.sales_name_f &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    value={fields.sales_name_f || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.sales_name_f &&
                    !errors.emailid &&
                    !errors.phone &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales_name_f
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Employee Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Employee Last Name"
                    style={
                      errors.sales_name_l &&
                      !errors.sales_name_f &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="sales_name_l"
                    id="sales_name_l"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.sales_name_l &&
                      !errors.sales_name_f &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    value={fields.sales_name_l || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.sales_name_l &&
                    !errors.sales_name_f &&
                    !errors.emailid &&
                    !errors.phone &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales_name_l
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub" style={{ marginBottom: "10px" }}>
                    Store Location <span className="red_point">*</span>
                  </label>

                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select Location"
                    options={locations}
                    isSearchable={true}
                    isClearable={true}
                    name="store_location"
                    ref={
                      errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["store_location"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["store_location"]: "",
                          };
                        });
                      }
                    }}
                  />

                  <div className="errorMsg">
                    {errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f &&
                    !errors.emailid &&
                    !errors.phone &&
                    !errors.lname &&
                    !errors.name
                      ? errors.store_location
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub" style={{ lineHeight: "19px" }}>
                    Was the customer’s visit to Ashley to shop for furniture or
                    mattress or both?
                    <span className="red_point">*</span>
                  </label>
                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.question &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select Option"
                    options={question}
                    isSearchable={true}
                    isClearable={true}
                    name="question"
                    ref={
                      errors.question &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["question"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["question"]: "",
                          };
                        });
                      }
                    }}
                  />

                  <label className="inline ms-2 formsub">
                    <div className="errorMsg d-inline">
                      {" "}
                      {errors.question &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.lname &&
                      !errors.name
                        ? errors.question
                        : null}
                    </div>
                  </label>
                </div>

                <div className="clearfix"></div>
              </div>

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn2 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default RegisterForm;
